import "../CSS/PageButton.css"

const PageButton = (props) => {
    return (
        <div className={props.selected 
                ? 'pageSelected Button pageButton bold' 
                : 'Button pageButton bold'} 
              onClick={props.onPress}>
            {props.text}
        </div>
    )
}

export default PageButton