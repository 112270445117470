import { useEffect } from 'react';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, isEmployee, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && (!user || !isEmployee)) {
      navigate("/login");
    }
  }, [user, isEmployee, loading]);

  return (user && isEmployee) ? <Component {...rest} /> : null;
};

export default ProtectedRoute;
