import { useEffect, useState } from "react";
import firebase from "../firebase";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const colors = [
  '#D35400',  // 深橙色
  '#16A085',  // 青绿色
  '#9ACD32',  // 黄绿色
  '#1ABC9C',  // 青色
  '#2ECC71',  // 绿色
  '#F1C40F',  // 黄色
  '#2980B9',  // 深蓝色
  '#FFAEB9',  // 淡粉色
  '#C0392B',  // 深红色
  '#27AE60',  // 深绿色
  '#EEC591',  // 淡黄色
  '#DDA0DD',  // 浅紫色
  '#87CEFF',  // 天蓝色
];

function hashCode(s) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = Math.imul(31, hash) + s.charCodeAt(i) | 0;
  }
  return hash;
}

const canceledOrderColor = '#A9A9A9';  // 灰色，代表订单取消

function getColor(order) {
  if (order.orderStatus === '订单取消') {
    return canceledOrderColor;
  }

  const combined = order.businessType + order.service.serviceType;
  const hash = hashCode(combined);
  return colors[Math.abs(hash) % colors.length];
}

const OrdersCalendar = () => {
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());
  const navigate = useNavigate();

  useEffect(() => {
    let query = firebase.firestore().collection("orders");

    query = query.orderBy("service.serviceDate", "asc");  // order by serviceDate in ascending order

    const unsubscribe = query.onSnapshot((snapshot) => {
      let newEvents = snapshot.docs.map((doc) => {
        let order = doc.data();

        if (!order.service || !order.service.serviceDate || !order.service.serviceTime) {
          console.log("Missing required field in order:", order);
          return null;  // return null if the order is missing a required field
        }

        // if duration does not exist, assign it a default value of 15 minutes
        let durationMinutes;
        if (!order.service.duration) {
          durationMinutes = 15;
        } else {
          // convert duration from hours to minutes
          durationMinutes = parseInt(order.service.duration.replace('小时', '')) * 60;
        }

        // merge date and time into a single string and remove any trailing whitespace
        const dateTime = `${order.service.serviceDate} ${order.service.serviceTime}`.trim();

        let title;

        // 如果业务类型是"清洁打扫"并且有janitorAmount
        if(order.businessType === "清洁打扫" && order.service.janitorAmount) {
            title = `${order.businessType} - ${order.service.serviceType} - ${order.service.janitorAmount} - ${order.firstname} ${order.lastname}`;
        } else {
            title = `${order.businessType} - ${order.service.serviceType} - ${order.firstname} ${order.lastname}`;
        }

        let event = {
          title: title,
          start: moment(dateTime, "YYYY-MM-DD HH:mm").toDate(), // convert dateTime to Date object
          end: moment(dateTime, "YYYY-MM-DD HH:mm").add(durationMinutes, "minutes").toDate(), // add duration to start time to get end time
          allDay: false, // set allDay to false as we now have specific start and end times
          resource: order,
          color: getColor(order),
        };

        return event;
      });

      // Filter out null events
      newEvents = newEvents.filter(event => event !== null);

      setEvents(newEvents);
    });

    // Remember to unsubscribe from Firestore on unmount
    return () => unsubscribe();
  }, [startDate, endDate]);

  return (
    <div style={{ height: "500pt" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onRangeChange={({ start, end }) => {  // updates startDate and endDate when calendar range changes
          setStartDate(start);
          setEndDate(end);
        }}
        onSelectEvent={event => {
          navigate(`/home/orders/${event.resource.orderId}`);
        }}
        eventPropGetter={event => {
          return {
            style: {
              backgroundColor: event.color,
            },
          };
        }}
      />
    </div>
  );
};

export default OrdersCalendar;
