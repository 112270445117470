import "../CSS/Order.css";

const Employee = (props) => {
  const order = props.order;

  return (
    <div className="order b flex mb4">
      <div className="empNum"> #{order.id}</div>
      <div className="empName"> {order.custName}</div>
      <div className="empProj"> {order.name}</div>
      <div className="b empOrderId"> {order.type}</div>
      <div
        className={
          order.status === "待服务"
            ? "await flex empStatus"
            : order.status === "服务中"
            ? "service flex empStatus"
            : "finished flex empStatus"
        }
      >
        {" "}
        {order.status}{" "}
      </div>
      <div className="empContact"> {order.createTime} </div>
      <div className="createTime"> {order.createTime} </div>
      <div className="flex serviceCount">
        {" "}
        {order.paymentAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        })}{" "}
      </div>
      <div className="flex orderButtons">
        <img
          src={require("../imgs/edit.png")}
          alt="None"
          className="editButton Button"
        />
        <img
          src={require("../imgs/delete.png")}
          alt="None"
          className="editButton Button"
        />
      </div>
    </div>
  );
};

export default Employee;
