import { React } from "react";
import Calendar from "react-calendar";
import Profile from "./Profile";
import NotificationBox from "./NotificationBox";
import notification from "../consts/notification";
import "../CSS/Info.css";

const Info = (props) => {
  return (
    <div className="Info">
      <Profile user={props.user} />
      <Calendar value={props.selectedDate} onChange={props.setSelectedDate} />
      <NotificationBox notifications={notification} />
    </div>
  );
};

export default Info;
