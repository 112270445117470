import PageButton from "./PageButton";

const PageSelect = (props) => {
  const { page, numPages, changePage } = props;

  const pagesToShow = 5; // You can adjust this number as per your preference

  // Calculate the range of page numbers to show in the pagination
  let startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  let endPage = Math.min(numPages, startPage + pagesToShow - 1);
  if (endPage - startPage < pagesToShow - 1) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  const pages = Array.from(Array(endPage - startPage + 1), (_, index) => startPage + index);

  return (
    <div className="flex pageSelect">
      <div className="orderTrackingText b pageNum">
        第{page}页，共{numPages}页
      </div>
      <PageButton
        text="上一页"
        onPress={() => {
          if (page > 1) {
            changePage(Math.max(page - 1, 1));
          }
        }}
      />

      {startPage > 1 && <PageButton text="1" onPress={() => changePage(1)} />}
      {startPage > 2 && <span className="ellipsis">...</span>}
      {pages.map((key) => {
        return (
          <PageButton
            selected={key === page}
            key={key}
            text={key}
            onPress={() => {
              changePage(key);
            }}
            // Add a fixed width to the button to prevent size jumping
            style={{ width: "30px", textAlign: "center" }}
          />
        );
      })}
      {endPage < numPages - 1 && <span className="ellipsis">...</span>}
      {endPage < numPages && (
        <PageButton
          text={numPages}
          onPress={() => changePage(numPages)}
          // Add a fixed width to the button to prevent size jumping
          style={{ width: "30px", textAlign: "center" }}
        />
      )}

      <PageButton
        text="下一页"
        onPress={() => {
          if (page < numPages) {
            changePage(Math.min(page + 1, numPages));
          }
        }}
      />
    </div>
  );
};

export default PageSelect;
