import { Component } from "react";
import firebase from "../firebase";
import LineChart from "./LineChart";
import "../CSS/OrderData.css";
class OrderData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(Date.now()),
      cleaning: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      shipping: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      moving: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      storing: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
    };
  }

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection("orders")
      .orderBy("generatedTime", "desc")
      .onSnapshot((snapshot) => {
        const cleaning = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        const shipping = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        const moving = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        const storing = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        snapshot.forEach((doc) => {
          const month = doc.data().generatedTime.toDate().getMonth() + 1;
          if (
            doc.data().generatedTime.toDate().getYear() !==
            this.state.date.getYear()
          )
            return;
          if (doc.data().businessType === "清洁打扫") cleaning[month] += 1;
          else if (doc.data().businessType === "快递服务") shipping[month] += 1;
          else if (doc.data().businessType === "搬家服务") moving[month] += 1;
          else if (doc.data().businessType === "仓库存储") storing[month] += 1;
        });
        this.setState({ cleaning, shipping, moving, storing });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { cleaning, shipping, moving, storing } = this.state;
    const reserveMonthly = { cleaning, shipping, moving, storing };

    return (
      <div className="orderCount">
        <div className="title">订单数量</div>
        <div className="orderLegend">
          <div className="yellowCircle" />
          <div className="orderText">清洁打扫</div>
          <div className="orangeCircle" />
          <div className="orderText">快递服务</div>
          <div className="blueCircle" />
          <div className="orderText">搬家服务</div>
          <div className="darkBlueCircle" />
          <div className="orderText">仓库存储</div>
          <div className="yearSelection">今年ˇ</div>
        </div>
        <LineChart data={reserveMonthly} />
      </div>
    );
  }
}

export default OrderData;
