import { useState, Component } from "react";
// import { doc, deleteDoc } from "firebase/firestore";
import firebase from "../firebase";
import SelectionBar from "./SelectionBar";
import Order from "./Order";
import PageSelect from "./PageSelect";
// import { orders } from "../consts/orders.js";
import "../CSS/OrderTracking.css";

const timeSelect = ["全部", "今日", "本周", "本月"];
const typeSelect = ["全部", "清洁打扫", "快递服务", "搬家服务", "仓库存储"];
const statusSelect = ["全部", "待服务", "服务中", "已完成", "已取消"];
const ordersPerPage = 7;

class OrderTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      status: 0,
      type: 0,
      period: 0,
      page: 1, // 添加 page 到组件状态
      maxIDLength: 10,
    };
  }

  changePeriod = (newPeriod) => {
    this.setState({ period: newPeriod });
  };

  changeType = (newType) => {
    const { status, period } = this.state;
    this.setState({ type: newType });
    this.fetchData(status, newType, period);
  };

  changeStatus = (newStatus) => {
    this.setState({ status: newStatus });
  };

  changePage = (newPage) => {
    this.setState({ page: newPage });
  };

  deleteOrder = (uid, id) => {
    const { status, type, period } = this.state;
  
    // 弹出一个确认对话框
    const confirmed = window.confirm('您确定要删除此订单么?');
  
    // 如果用户点击了“确定”，那么删除订单
    if (confirmed) {
      firebase.firestore().collection("orders").doc(id).delete();
      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("orders")
        .doc(id)
        .delete();
      this.fetchData(status, type, period);
    }
  };
  

  fetchData = (status, type, period) => {
    this.unsubscribe = firebase
      .firestore()
      .collection("orders")
      .orderBy("generatedTime", "desc")
      .onSnapshot((snapshot) => {
        const orders = [];
        snapshot.forEach((doc) => {
          if (type !== 0 && doc.data().businessType !== typeSelect[type])
            return;
          // if (status !== 0 && doc.data().status !== statusSelect[status])
          //   return;
          orders.push({ id: doc.id, ...doc.data() });
        });
        this.setState({ orders });
      });
  };

  componentDidMount() {
    const { status, type, period } = this.state;
    this.fetchData(status, type, period);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { orders, status, type, period, page } = this.state;
    const numPages = Math.ceil(
      (Object.keys(orders).length - 1) / ordersPerPage
    );

    return (
      <div className="tracking flex-column">
        <div className="timeSelect flex">
          <div className="trackingTitle b">订单明细</div>
          <SelectionBar
            selections={timeSelect}
            onPress={this.changePeriod}
            selected={period}
          />
        </div>

        <div className="typeSelect flex items-center">
          <div className="orderTrackingText b">项目类型</div>
          <SelectionBar
            selections={typeSelect}
            onPress={this.changeType}
            selected={type}
          />
        </div>

        <div className="statusSelect flex items-center">
          <div className="orderTrackingText b">订单状态</div>
          <SelectionBar
            selections={statusSelect}
            onPress={this.changeStatus}
            selected={status}
          />
        </div>

        <div className="flex-column orderTable">
          <div className="flex-column orderTop">
            <div className="flex orderList">
              <div className="flex blueText b orderNum">订单号</div>
              <div className="flex blueText b orderType">项目类型</div>
              <div className="flex blueText b orderName">项目名称</div>
              <div className="flex blueText b custName">客户姓名</div>
              <div className="flex blueText b serviceMember">服务人员</div>
              <div className="flex blueText b orderStatus">状态</div>
              <div className="flex blueText b createTime">创建时间</div>
              <div className="flex blueText b fee">金额</div>
              <div className="flex blueText b actions">操作</div>
            </div>

            <div>
              {Object.values(orders).slice((page - 1) * ordersPerPage, page * ordersPerPage).map((order, key) => {
                return (
                  <Order
                    key={key}
                    order={order}
                    changePage={this.changePage}
                    deleteOrder={this.deleteOrder}
                  />
                );
              })}
            </div>

          </div>

          <PageSelect
            page={page}
            numPages={numPages}
            changePage={this.changePage}
          />
        </div>
      </div>
    );
  }
}

export default OrderTracking;
