import { useState, Component } from "react";
import { orders } from "../consts/orders";
import firebase from "../firebase";
import SelectionBar from "./SelectionBar";
import Employee from "./Employee";
import PageSelect from "./PageSelect";
import "../CSS/EmployeeTracking.css";

const empPerPage = 7;
const typeSelect = ["全部", "清洁打扫", "快递服务", "搬家服务", "仓库存储"];
const statusSelect = ["全部", "前往中", "服务中", "暂无订单"];

class EmployeeTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      status: 0,
      type: 0,
      page: 1, // 添加 page 到组件状态
      maxIDLength: 10,
    };
  }

  changeType = (newType) => {
    this.setState({ type: newType });
  };

  changeStatus = (newStatus) => {
    this.setState({ status: newStatus });
  };

  changePage = (newPage) => {
    this.setState({ page: newPage });
  };

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection("customers")
      .onSnapshot((snapshot) => {
        const users = [];
        snapshot.forEach((doc) => {
          users.push({ uid: doc.id, ...doc.data() });
        });
        this.setState({ users });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { employees, status, type, page } = this.state;
    const numPages = Math.ceil((Object.keys(orders).length - 1) / empPerPage);

    return (
      <div className="tracking flex-column">
        <div className="timeSelect flex">
          <div className="emptrackingTitle b">人员管理</div>
        </div>

        <div className="typeSelect flex items-center">
          <div className="empTrackingText b">项目类型</div>
          <SelectionBar
            selections={typeSelect}
            onPress={this.changeType}
            selected={type}
          />
        </div>

        <div className="statusSelect flex items-center">
          <div className="empTrackingText b">订单状态</div>
          <SelectionBar
            selections={statusSelect}
            onPress={this.changeStatus}
            selected={status}
          />
        </div>

        <div className="flex-column empTable">
          <div className="flex-column empTop">
            <div className="flex empList">
              <div className="blueText b empNum">人员ID</div>
              <div className="blueText b empName">人员姓名</div>
              <div className="blueText b empProj">所属项目</div>
              <div className="blueText b empOrderId">订单ID</div>
              <div className="blueText b empStatus">人员状态</div>
              <div className="blueText b empContact">联系方式</div>
              <div className="blueText b createTime">注册时间</div>
              <div className="blueText b serviceCount">服务次数</div>
              <div className="blueText b actions">操作</div>
            </div>

            <div>
              {Object.keys(orders).map((key) => {
                if (key > (page - 1) * empPerPage && key <= page * empPerPage) {
                  return (
                    <Employee
                      key={key}
                      order={orders[key]}
                      changePage={this.changePage}
                    />
                  );
                }
              })}
            </div>
          </div>

          <PageSelect
            page={page}
            numPages={numPages}
            changePage={this.changePage}
          />
        </div>
      </div>
    );
  }
}

export default EmployeeTracking;
