import React, { Component } from "react";
import firebase from "../firebase";
import BarChart from "./BarChart";
import "../CSS/Income.css";
import { all } from "q";

class Income extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(Date.now()),
      received: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      awaiting: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
    };
  }

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection("orders")
      .orderBy("generatedTime", "desc")
      .onSnapshot((snapshot) => {
        const income = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        snapshot.forEach((doc) => {
          const month = doc.data().generatedTime.toDate().getMonth() + 1;
          if (
            doc.data().generatedTime.toDate().getYear() !==
            this.state.date.getYear()
          )
            return;
          const paymentAmount = parseFloat(doc.data().paymentAmount);
          if (!isNaN(paymentAmount)) {
            income[month] += parseFloat(paymentAmount.toFixed(2));
          }
        });
        this.setState({ received: income });
      });
  }


  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { received, awaiting } = this.state;
    const allIncome = { received, awaiting };

    return (
      <div className="income">
        <div className="title">营业收入</div>
        <div className="incomeStat">
          <div className="totalIncome blueText">
            {Object.values(allIncome.received)
              .reduce((total, income) => total + income, 0)
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              })}
          </div>
          <div className="blueCircle" />
          <div className="incomeText">已入账</div>
          <div className="yellowCircle" />
          <div className="incomeText">未入账</div>
          <div className="monthSelection">当年ˇ</div>
        </div>
        <BarChart data={allIncome} />
      </div>
    );
  }
}

export default Income;
