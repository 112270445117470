import { LinkContainer } from "react-router-bootstrap";
import "../CSS/Order.css";

const Order = (props) => {
  const { order, deleteOrder } = props;
  const maxIDLength = 10;
  const time = order.generatedTime;
  const timeStamp = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
  const timeDisplay =
    timeStamp.getMonth() +
    1 +
    "/" +
    timeStamp.getDate() +
    "/" +
    timeStamp.getFullYear();

  let statusClass;
  switch (order.orderStatus) {
    case "支付成功":
      statusClass = "await";
      break;
    case "订单确认":
      statusClass = "service";
      break;
    case "订单完成":
      statusClass = "finished";
      break;
    case "订单取消":
      statusClass = "cancelled";
      break;
    default:
      statusClass = "";
  }

  return (
    <div className="order b flex">
      <LinkContainer to={`/home/orders/${order.id}`}>
        <div className="flex">
          <div className="flex orderNum">
            {order.orderId.length > maxIDLength ? "..." : ""}
            {order.orderId.slice(
              order.orderId.length - maxIDLength,
              order.orderId.length
            )}
          </div>
          <div className="flex orderType"> {order.businessType}</div>
          <div className="flex orderName"> {order.service.serviceType}</div>
          <div className="flex custName">
            {order.firstname} {order.lastname}
          </div>
          <div className="flex serviceMember">
            {/* {Object.keys(order.serviceMember).map((key) => {
          return <div className="greyCircle" key={key}></div>;
        })} */}
            {order.service.janitorAmount}
          </div>
          <div className={`flex orderStatus ${statusClass}`}>
            {order.orderStatus}
          </div>
          <div className="createTime">{timeDisplay}</div>
          <div className="flex fee">
            {" "}
            {order.paymentAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            })}{" "}
          </div>
        </div>
      </LinkContainer>
      <div className="flex orderButtons ml2">
        <img
          src={require("../imgs/edit.png")}
          alt="None"
          className="editButton Button"
        />
        <img
          src={require("../imgs/delete.png")}
          alt="None"
          className="editButton Button"
          onClick={() => deleteOrder(order.uid, order.id)}
        />
      </div>
    </div>
  );
};

export default Order;
