import { React } from "react";
import firebase from "../firebase";
import "../CSS/Profile.css";

const Profile = (props) => {
  const auth = firebase.auth();
  console.log(auth.currentUser);

  // Sign out function
  const signOut = () => {
    auth.signOut().then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  return (
    <div className="Profile">
      <img
        src={require("../imgs/defaultImg.png")}
        alt="none"
        className="profPic"
      />
      {/* { props.user.image === '' 
            ? <img src={require("../imgs/defaultImg.png")} alt="none" className="profPic"/>
            : <img src={require(props.user.img)} alt="none" className="profPic"/>
          } */}
      <div className="profInfo">
        <div className="profName">{}</div>
        <div className="profAccess">{}</div>
        <a class="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-red" href="#0" onClick={signOut}>退出登录</a>
      </div>
    </div>
  );
};

export default Profile;
