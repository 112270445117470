import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { months, monthsCh } from "../Utilities/Util";

const LineChart = (props) => {
  const labels = monthsCh({ count: 12 });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "清洁打扫",
        data: Object.keys(props.data.cleaning).map(
          (key) => props.data.cleaning[key]
        ),
        borderColor: "#F7B321",
        pointRadius: 0,
      },
      {
        label: "快递服务",
        data: Object.keys(props.data.shipping).map(
          (key) => props.data.shipping[key]
        ),
        borderColor: "#ff6f09",
        pointRadius: 0,
      },
      {
        label: "搬家服务",
        data: Object.keys(props.data.moving).map(
          (key) => props.data.moving[key]
        ),
        borderColor: "#59C0E4",
        pointRadius: 0,
      },
      {
        label: "仓库存储",
        data: Object.keys(props.data.storing).map(
          (key) => props.data.storing[key]
        ),
        borderColor: "#3A47C3",
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 9,
          },
        },
      },
    },
  };

  return (
    <div className="lineChart">
      <Line labels={labels} data={data} options={options} />
    </div>
  );
};

export default LineChart;
