const SelectionBar = (props) => {
  const { selections, selected } = props;

  return (
    <div className="selectionBar flex">
      {selections.map((selection, index) => {
        return (
          <div
            className={index === selected ? "selection selected" : "selection"}
            key={index}
            onClick={() => props.onPress(index)}
          >
            <div className="selectionText b">{selection}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SelectionBar;
