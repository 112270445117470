import "../CSS/Reservation.css";
import React, { Component } from "react";
import { reserve } from "../consts/reserve";
import firebase from "../firebase";
import DoughnutChart from "./DoughnutChart";
import { Link } from 'react-router-dom';

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(Date.now()),
      cleaning: null,
      shipping: 0,
      moving: 0,
      storing: 0,
    };
  }

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection("orders")
      .orderBy("generatedTime", "desc")
      .onSnapshot((snapshot) => {
        var cleaning = 0;
        var shipping = 0;
        var moving = 0;
        var storing = 0;
        snapshot.forEach((doc) => {
          if (
            doc.data().generatedTime.toDate().getYear() !==
            this.state.date.getYear()
          )
            return;
          if (
            doc.data().generatedTime.toDate().getMonth() !==
            this.state.date.getMonth()
          )
            return;
          if (doc.data().businessType === "清洁打扫") {
            cleaning++;
          } else if (doc.data().businessType === "快递服务") {
            shipping++;
          } else if (doc.data().businessType === "搬家服务") {
            moving++;
          } else if (doc.data().businessType === "仓库存储") {
            storing++;
          }
        });
        this.setState({
          cleaning: cleaning,
          shipping: shipping,
          moving: moving,
          storing: storing,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { cleaning, shipping, moving, storing } = this.state;
    const reserve = { cleaning, shipping, moving, storing };
    console.log(reserve);

    return (
      <div className="reservation">
        {this.state.cleaning === null ? (
          <div>loading</div>
        ) : (
          <div>
            <div className="title">预约管理</div>
            <div className="reserveContent">
              <div className="reserveLeft">
                <div className="totalReserve blueText">
                  {cleaning + shipping + moving + storing}
                </div>
                <div className="reserved">已预约</div>
                <div className="graphTitle">分布</div>
                <DoughnutChart width="150px" data={reserve} />
              </div>

              <div className="reserveRight">
                <div className="monthSelection">当月ˇ</div>
                <div className="reserve resTop">清洁打扫</div>
                <div className="yellowText reserveCount">{cleaning}</div>
                <div className="reserve">快递服务</div>
                <div className="orangeText reserveCount">{shipping}</div>
                <div className="reserve">搬家服务</div>
                <div className="lightBlueText reserveCount">{moving}</div>
                <div className="reserve">仓库存储</div>
                <div className="blueText reserveCount">{storing}</div>
              </div>
            </div>
            <Link to="/home/orders">
              <div className="details blueText" > 预约详情 > </div>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default Reservation;
