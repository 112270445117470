import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import firebase from "../firebase";
import SelectionBar from "./SelectionBar";
import PageSelect from "./PageSelect";
import "../CSS/CustomerTracking.css";
import PushNotificationForm from './PushNotificationForm';

const CustomerList = () => {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(0);
  const [page, setPage] = useState(1);
  const [maxIDLength, setMaxIDLength] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("customers")
      .orderBy('createdAt', 'desc') // 添加此行进行排序
      .onSnapshot((snapshot) => {
        const newUsers = [];
        snapshot.forEach((doc) => {
          newUsers.push({ uid: doc.id, ...doc.data() });
        });
        setUsers(newUsers);
      });

    // Cleanup function to unsubscribe from the snapshot
    return () => unsubscribe();
  }, []);


  const handlePushManagementClick = () => {
    navigate(`/home/pushnotificationform`);  // 跳转到推送通知表单页面
  };

  const statusSelect = ["全部", "待服务", "服务中", "已完成", "已取消"];
  const custPerPage = 10;
  const numPages = Math.ceil((Object.keys(users).length - 1) / custPerPage);

  return (
    <div className="tracking flex-column">
      <div className="timeSelect flex">
        <div className="trackingTitle b">客户管理</div>
        <SelectionBar
          selections={statusSelect}
          onPress={setStatus}
          selected={status}
        />
        <button className="f6 grow no-underline br-pill ba bw2 ph3 pv2 mb2 dib dark-blue ml3" onClick={() => navigate(`/home/pushnotificationform`)} >推送管理</button>
        <button className="f6 grow no-underline br-pill ba bw2 ph3 pv2 mb2 dib dark-blue ml3" onClick={() => navigate(`/home/manageads`)} >主页广告展示管理</button>
        <button className="f6 grow no-underline br-pill ba bw2 ph3 pv2 mb2 dib dark-blue ml3" onClick={() => navigate(`/home/coupons`)} >优惠券管理</button>
      </div>

      <div className="flex-column custTable">
        <div className="flex-column">
          <div className="flex custList">
            <div className="flex blueText b custID">客户ID</div>
            <div className="flex blueText b custName2">客户姓名</div>
            <div className="flex blueText b defaultAddr">默认地址</div>
            <div className="flex blueText b contactNum">联系电话</div>
            <div className="flex blueText b email">Email</div>
            <div className="flex blueText b registTime">注册时间</div>
            <div className="flex blueText b reserveCount2">预定次数</div>
            <div className="flex blueText b actions2">操作</div>
          </div>

          <div className="custList mt4">
            <div>
              {Object.keys(users).map((key) => {
                if (
                  key > (page - 1) * custPerPage &&
                  key <= page * custPerPage
                ) {
                  console.log(users[key].createdAt)
                  return (
                    <div className="mb3" key={key}>
                      <div className="flex">
                        <div className="flex mb2 b custID">
                          {users[key].uid.length > maxIDLength
                            ? "..."
                            : ""}
                          {users[key].uid.slice(
                            users[key].uid.length - maxIDLength,
                            users[key].uid.length
                          )}
                        </div>
                        <div className="flex b custName2">
                          {users[key].username || users[key].tempUserName || ""}
                        </div>
                        <div className="flex b defaultAddr"></div>
                        <div className="flex b contactNum">
                          {users[key].phone}
                        </div>
                        <div className="flex b email">{users[key].email}</div>
                        <div className="flex b regisTime">
                          {users[key].createdAt ? moment(new Date(users[key].createdAt.seconds * 1000)).format('YYYY-MM-DD') : "N/A"}
                        </div>


                        <div className="flex b reserveCount2"></div>
                        {/* Add more cells for other user properties, if needed */}
                      </div>
                    </div>
                  );
                }
              })}
            </div>

          </div>
        </div>
      </div>
      <PageSelect
        page={page}
        numPages={numPages}
        changePage={setPage}
        className="pageSelectMargin"
      />
    </div>

  );
};

export default CustomerList;
