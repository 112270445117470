import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { months, monthsCh } from "../Utilities/Util";

const BarChart = (props) => {
  const labels = monthsCh({ count: 12 });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "已入账",
        data: Object.keys(props.data.received).map(
          (key) => props.data.received[key]
        ),
        backgroundColor: "#59C0E4",
        barThickness: 9,
      },
      {
        label: "未入账",
        data: Object.keys(props.data.awaiting).map(
          (key) => props.data.awaiting[key]
        ),
        backgroundColor: "#F7B321",
        barThickness: 9,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            if (value === 0) return 0;
            return value / 1000 + "k";
          },
        },
        border: {
          display: false,
        },
        padding: 0,
        title: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 9,
          },
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Bar
        className="barChart"
        labels={data.labels}
        data={data}
        options={options}
      />
    </div>
  );
};

export default BarChart;
