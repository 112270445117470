import { useState } from "react";
import { useOutletContext } from "react-router";
import SelectionBar from "./SelectionBar";
import ContactList from "./ContactList";
import ChatScreen from "./ChatScreen";
import { chats } from "../consts/chats";
import "../CSS/Chat.css";

const Chat = (props) => {
  const user = useOutletContext();
  const chatSelect = ["全部", "消息", "通知中心"];
  const [chatState, setChatState] = useState(0);
  const [chatSelected, setChatSelected] = useState("");

  return (
    <div className="flex-column chat">
      <div className="flex chatSelect">
        <SelectionBar
          selections={chatSelect}
          selected={chatState}
          onPress={setChatState}
        />
      </div>
      <div className="flex">
        <ContactList onPress={setChatSelected} currChat={chatSelected} />
        <ChatScreen chatRoomId={chatSelected} user={user} />
      </div>
    </div>
  );
};

export default Chat;
