import { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import "../CSS/Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setUsername(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  const login = (event) => {
    event.preventDefault();
    const auth = firebase.auth();
    signInWithEmailAndPassword(auth, username, password)
      .then(async (userCredentials) => {
        const user = userCredentials.user;
        const userDoc = doc(firebase.firestore(), 'customers', user.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          if (userData.isEmployee) {
            navigate("/home/logistics");
            console.log("Logged in with:", user.email);
          } else {
            alert('此用户没有员工权限。');
          }
        } else {
          alert('找不到用户。');
        }
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="flex w-100 h-100 white">
      <div className="flex w-50 items-center">
        <div className="flex-row w-100">
          <div className="">
            <h1 className="flex pr5 title f-subheadline b mv0"> LIFEBANG </h1>
          </div>
          <div className="">
            <h1 className="flex pr5 title f-subheadline b mv0">后台管理系统</h1>
          </div>
        </div>
      </div>
      <div className="flex w-50 items-center">
        <form className="flex-row w-100">
          <div className="f3 b mb2">账号登录</div>
          <div className="w-60 mb3">
            <input
              className="login-input w-100 br2 bw0 f5 bg-near-white"
              type="text"
              name="userEmail"
              placeholder="用户名"
              onChange={onChangeHandler}
            />
          </div>
          <div className="w-60 mb3">
            <input
              className="login-input w-100 br2 bw0 f5 bg-near-white"
              type="password"
              name="userPassword"
              placeholder="密码"
              onChange={onChangeHandler}
            />
          </div>
          <div className="w-60 flex justify-between mb3">
            <label>
              <input
                className="bw0 mr2"
                type="checkbox"
                value="登录"
                placeholder="记住密码"
              />
              记住密码
            </label>
            <div className="">忘记密码？</div>
          </div>
          <div className="w-60">
            <input
              className="login-button w-100 br2 bw0 bg-black white f5"
              type="submit"
              value="登录"
              onClick={login}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
