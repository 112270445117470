const notification =  {
    1: {
        type: "user",
        user: '王阿姨',
        name: "状态更新",
        orderNum: 7428,
        status: "已完成",
    },
    2: {
        type: "user",
        user: '周阿姨',
        name: "状态更新",
        orderNum: 5326,
        status: "前往",
    },
    3: {
        type: "system",
        user: "接单系统",
        name: "新预约",
        status: "预约",
        order : {
            service: "日常打扫",
            date: "5月14日",
            staffNum: "两位阿姨",
        }
    },
}

export default notification;