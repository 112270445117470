import { useState, useEffect } from "react";
import firebase from "../firebase"; // 导入Firebase
import "firebase/compat/firestore";
import Modal from "react-modal";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { LinkContainer } from "react-router-bootstrap";

import "../CSS/ChatScreen.css";
import { or } from "firebase/firestore";

Modal.setAppElement("#root");

const InputBox = (props) => {
  const { chatRoomId } = props; // 从props获取chatRoomId
  const [input, setInput] = useState("");
  const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
  const db = firebase.firestore(); // 获取Firebase Firestore实例
  const [image, setImage] = useState(null);
  const storage = firebase.storage();

  function generateUniqueId() {
    return (
      Date.now().toString(36) + Math.random().toString(36).substring(2, 15)
    );
  }

  // 表情选择
  const handleEmojiClick = (emoji, event) => {
    setInput(input + emoji.native); // 在输入框末尾添加选中的表情
  };

  const toggleEmojiPicker = (event) => {
    event.stopPropagation();
    setEmojiPickerIsOpen(!emojiPickerIsOpen);
  };

  // 图片发送
  const handleFileInput = (event) => {
    // 检查是否有文件被选中
    if (event.target.files && event.target.files[0]) {
      // 检查文件类型，只接受图片
      const allowedTypes = ["image/png", "image/jpeg", "image/gif"];
      if (!allowedTypes.includes(event.target.files[0].type)) {
        alert("只支持 PNG, JPEG, 和 GIF 格式的图片。");
        return;
      }
      handleSendImage(event.target.files[0]); // 将选中的文件直接作为参数传递给 handleSendImage
    }
  };

  const handleSendImage = async (file) => {
    const storageRef = storage.ref();
    const imagesRef = storageRef.child("images/" + file.name);
    await imagesRef.put(file);
    setImage(null); // 发送图片后再将 image 置为 null
    const url = await imagesRef.getDownloadURL();

    const newMessage = {
      image: url,
      createdAt: new Date(),
      _id: generateUniqueId(),
      user: {
        _id: "CtczBrr4wUMJKVrZDoSf6WFJ3s22",
      },
    };

    setInput(""); // 发送后清空输入框

    await db
      .collection("chatRooms")
      .doc(chatRoomId)
      .collection("messages")
      .add(newMessage);
    await db
      .collection("chatRooms")
      .doc(chatRoomId)
      .update({
        lastMessage: "[图片]",
        lastMessageTime: newMessage.createdAt,
        customerUnreadNum: firebase.firestore.FieldValue.increment(1),
        employeeUnreadNum: 0,
      });

    // 发送推送消息
    // ...

    // 获取客户的expoPushToken
    const chatRoomDoc = await db.collection("chatRooms").doc(chatRoomId).get();
    const customerId = chatRoomDoc.data().customerId;
    const customerDoc = await db.collection("customers").doc(customerId).get();
    const expoPushToken = customerDoc.data().expoPushToken;

    // 如果找不到expoPushToken，直接返回
    if (!expoPushToken) {
      console.log("Cannot find expoPushToken for customer " + customerId);
      setInput(""); // 发送后清空输入框
      return;
    }

    // 定义推送消息
    const message = {
      to: expoPushToken,
      sound: "default",
      title: "生活帮客服",
      body: "[图片]",
      data: { chatRoomId },
    };

    // 发送推送消息
    try {
      await fetch("https://exp.host/--/api/v2/push/send", {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Accept-Encoding": "gzip, deflate",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });
    } catch (error) {
      console.error("Error sending push notification: ", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // 阻止默认的换行行为
      handleSend();
    }
  };

  const handleSend = async () => {
    if (input.trim() !== "") {
      const newMessage = {
        text: input,
        createdAt: new Date(),
        _id: generateUniqueId(),
        user: {
          _id: "CtczBrr4wUMJKVrZDoSf6WFJ3s22",
        },
      };

      setInput(""); // 发送后清空输入框

      await db
        .collection("chatRooms")
        .doc(chatRoomId)
        .collection("messages")
        .add(newMessage);
      await db
        .collection("chatRooms")
        .doc(chatRoomId)
        .update({
          lastMessage: newMessage.text,
          lastMessageTime: newMessage.createdAt,
          customerUnreadNum: firebase.firestore.FieldValue.increment(1),
          employeeUnreadNum: 0,
        });

      // 获取客户的expoPushToken
      const chatRoomDoc = await db
        .collection("chatRooms")
        .doc(chatRoomId)
        .get();
      const customerId = chatRoomDoc.data().customerId;
      const customerDoc = await db
        .collection("customers")
        .doc(customerId)
        .get();
      const expoPushToken = customerDoc.data().expoPushToken;

      // 如果找不到expoPushToken，直接返回
      if (!expoPushToken) {
        console.log("Cannot find expoPushToken for customer " + customerId);
        setInput(""); // 发送后清空输入框
        return;
      }

      // 定义推送消息
      const message = {
        to: expoPushToken,
        sound: "default",
        title: "生活帮客服",
        body: newMessage.text,
        data: { chatRoomId },
      };

      // 发送推送消息
      try {
        await fetch("https://exp.host/--/api/v2/push/send", {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Accept-Encoding": "gzip, deflate",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(message),
        });
      } catch (error) {
        console.error("Error sending push notification: ", error);
      }
    }
  };

  return (
    <div className="inputBox">
      <div className="flex Buttons">
        <div className="flex">
          <img
            className="utilityButton"
            src={require("../imgs/表情.png")}
            alt="none"
            onClick={toggleEmojiPicker}
          />

          <img
            className="utilityButton"
            src={require("../imgs/文件.png")}
            alt="none"
            onClick={() => document.getElementById("fileInput").click()}
          />
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileInput}
          />
          {/* <img
            className="utilityButton"
            src={require("../imgs/链接.png")}
            alt="none"
          /> */}
        </div>
        <div className="flex justify-end">
          <img
            className="sendButton"
            src={require("../imgs/发送.png")}
            alt="none"
            onClick={handleSend}
          />
        </div>
      </div>
      <textarea
        className="inputText"
        type="text"
        placeholder="输入信息"
        value={input}
        onChange={(text) => setInput(text.target.value)}
        onKeyDown={handleKeyDown} // 监听键盘按下事件
      />
      {emojiPickerIsOpen && (
        <Picker
          data={data}
          onEmojiSelect={handleEmojiClick}
          onClickOutside={toggleEmojiPicker}
        // onClickOutside={() => {
        //   setEmojiPickerIsOpen(false);
        // }}
        />
      )}
    </div>
  );
};

const Message = (props) => {
  const { type, msg, img } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContentStyle, setModalContentStyle] = useState({});

  const openModal = async () => {
    if (img) {
      const image = new Image();
      image.onload = () => {
        const maxWidth = window.innerWidth * 1;
        const maxHeight = window.innerHeight * 1;
        const aspectRatio = image.naturalWidth / image.naturalHeight;
        let width, height;
        if (image.naturalWidth > image.naturalHeight) {
          width = Math.min(maxWidth, image.naturalWidth);
          height = width / aspectRatio;
        } else {
          height = Math.min(maxHeight, image.naturalHeight);
          width = height * aspectRatio;
        }
        setModalContentStyle({
          width: `${width}px`,
          height: `${height}px`,
        });
      };
      image.src = img;
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={type === "received" ? "custMsg" : "memberMsg"}>
      {img ? (
        <div>
          <img
            src={img}
            alt=""
            style={{ width: "100px", height: "100px" }}
            onClick={openModal}
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            style={{
              content: {
                ...modalContentStyle,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "transparent",
                border: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
              },
            }}
          >
            <img
              src={img}
              alt=""
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </Modal>
        </div>
      ) : (
        msg
      )}
    </div>
  );
};

const SentMessage = (props) => {
  const { msg, img, type, sender } = props;

  return (
    <div className="flex rightMsg">
      <Message type={type} msg={msg} img={img} />
      {/* sender.image ? (
          <img className="profilePic" src={sender.img} alt="none" />
        ) : (
          <div className="greyCircle chatImg sProfPic" />
        )} */}
      <div className="greyCircle chatImg sProfPic" />
    </div>
  );
};

const ReceivedMessage = (props) => {
  const { msg, img, type, sender } = props;

  return (
    <div className="flex">
      {/* sender.image ? (
          <img className="profilePic" src={sender.img} alt="none" />
        ) : (
          <div className="greyCircle chatImg rProfPic" /> */}
      <div className="greyCircle chatImg rProfPic" />
      <Message type={type} msg={msg} img={img} />
    </div>
  );
};

const ChatScreen = (props) => {
  const { user, chatRoomId } = props;
  const msgAmount = 30; // 限制拉取消息数量
  const [messages, setMessages] = useState([]); // 新增状态钩子来存储消息
  const [chatUser, setChatUser] = useState(null); // 新增状态钩子来存储聊天室用户信息
  const db = firebase.firestore(); // 获取Firebase Firestore实例

  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const toggleOrdersModal = () => {
    setShowOrdersModal(!showOrdersModal);
  }

  console.log(chatRoomId);
  console.log(chatUser);

  // 添加一个副作用钩子来监听chatRoomId的变化
  useEffect(() => {
    const fetchChatRoomInfo = async () => {
      const chatRoomDoc = await db
        .collection("chatRooms")
        .doc(chatRoomId)
        .get();
      const customerId = chatRoomDoc.data().customerId;
      const customerDoc = await db
        .collection("customers")
        .doc(customerId)
        .get();

      if (customerId) {
        const ordersCollection = await db.collection("customers").doc(customerId).collection("orders").get();
        const fetchedOrders = ordersCollection.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrderList(fetchedOrders);
      }
      const { avatar, username, phoneNumber, email, tempUserName, stripeId, } = customerDoc.data();
      // console.log(customerOrders.data());
      let displayName = username || tempUserName || phoneNumber || email;
      displayName = displayName || "未设置用户名";
      setChatUser({
        name: displayName,
        img: avatar,
        stripeId: stripeId,
      });
    };

    if (chatRoomId) {
      fetchChatRoomInfo();
    }
  }, [chatRoomId, db]);

  // 添加一个副作用钩子来监听消息的变化
  useEffect(() => {
    // 只有在chatRoomId存在时才获取数据
    if (chatRoomId) {
      const unsubscribe = db
        .collection("chatRooms")
        .doc(chatRoomId)
        .collection("messages")
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          let newMessages = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setMessages(newMessages);
        });

      return () => unsubscribe(); // 清理订阅
    }
  }, [db, chatRoomId]); // 当chatRoomId改变时更新副作用

  return (
    <div className="flex-column chatScreen">
      {!chatUser ? (
        <></>
      ) : (
        <>
          <div className="chatContent">
            <div className="custInfo flex justify-between items-center pa2">
              <div className="f4 b">{chatUser.name}</div>
              {/* 增加历史订单按钮 */}
              <button className="orderButton bg-blue white pa2 br3 pointer f6" onClick={toggleOrdersModal}>历史订单</button>

              {/* 如果点击了历史订单按钮，显示订单模态框 */}
              {showOrdersModal && (
                <div className="ordersModal fixed top-0 left-0 w-100 h-100 flex justify-center items-center">
                  <div className="pa4 bg-white br2 shadow-4">
                    <div className="ordersModalClose flex justify-between items-center">
                      <div className="stripeId f4 b">
                        {chatUser.stripeId ? `Stripe ID: ${chatUser.stripeId}` : "无 Stripe ID"}
                      </div>
                      <button className="bg-red white pa1 br2 pointer" onClick={toggleOrdersModal}>✖</button>
                    </div>
                    {orderList && orderList.length > 0 ? (
                      <ul className="list pl0">
                        {orderList.map(order => (
                          <li className="mv2" key={order.id}>
                            <LinkContainer to={`/home/orders/${order.id}`}>
                              <a className="link blue">订单 #{order.id}</a>
                            </LinkContainer>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="f5 gray">此用户没有历史订单。</p>
                    )}
                  </div>
                </div>
              )}
            </div>


            <div className="messages">
              <div className="wrapper">
                {messages.map((msg) => {
                  const isSent =
                    msg.user._id === "CtczBrr4wUMJKVrZDoSf6WFJ3s22";
                  if (isSent) {
                    return (
                      <SentMessage
                        key={msg._id}
                        msg={msg.text}
                        img={msg.image}
                        type={"sent"}
                        sender={user}
                      />
                    );
                  } else {
                    return (
                      <ReceivedMessage
                        key={msg._id}
                        index={msg._id}
                        msg={msg.text}
                        img={msg.image}
                        type={"received"}
                        sender={msg.user}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <InputBox chatRoomId={chatRoomId} />
        </>
      )}
    </div>
  );
};

export default ChatScreen;
