import React, { useState } from 'react';
import firebase from "../firebase";

const firestore = firebase.firestore();

function CouponForm({ setCoupons, coupons, closeModal }) {
    const [code, setCode] = useState("");
    const [discount, setDiscount] = useState("");
    const [limit, setLimit] = useState("");
    const [error, setError] = useState("");

    const addCoupon = async () => {
        if (!code || !discount || !limit) {
            setError("所有字段均为必填项。");
            return;
        }

        if (isNaN(discount) || isNaN(limit) || parseInt(limit) < 0) {
            setError("折扣和限制必须为有效数字，且限制必须为正整数。");
            return;
        }

        const docRef = firestore.collection('coupons').doc();

        const now = firebase.firestore.FieldValue.serverTimestamp(); // Get a server timestamp

        const newCoupon = {
            id: docRef.id,
            code: code,
            discount: parseFloat(discount),
            limit: parseInt(limit),
            usedCount: 0,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        await docRef.set(newCoupon);

        // Use the new date as the placeholder until the server timestamp is set
        newCoupon.createdAt = { seconds: Date.now() / 1000, nanoseconds: 0 };

        setCoupons([...coupons, newCoupon]);

        setCode("");
        setDiscount("");
        setLimit("");
        closeModal();
    };

    const cancelAdd = () => {
        setCode("");
        setDiscount("");
        setLimit("");
        closeModal();
    }

    return (
        <div className="fixed top-0 left-0 w-100 h-100 flex items-center justify-center bg-black-70 z-5">
            <div className="pa4 bg-white">
                <h2>添加优惠券</h2>
                <p className="red">{error}</p>
                <div className="measure">
                    <label htmlFor="code" className="f6 b db mb2">优惠代码</label>
                    <input
                        className="input-reset ba b--black-20 pa2 mb2 db w-100"
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>
                <div className="measure">
                    <label htmlFor="discount" className="f6 b db mb2">折扣(金额)</label>
                    <input
                        className="input-reset ba b--black-20 pa2 mb2 db w-100"
                        type="text"
                        id="discount"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                    />
                </div>
                <div className="measure">
                    <label htmlFor="limit" className="f6 b db mb2">限制次数</label>
                    <input
                        className="input-reset ba b--black-20 pa2 mb2 db w-100"
                        type="text"
                        id="limit"
                        value={limit}
                        onChange={(e) => setLimit(e.target.value)}
                    />
                </div>
                <button onClick={addCoupon} className="f6 link dim br-pill ph3 pv2 mb2 dib white bg-green">添加</button>
                <button onClick={cancelAdd} className="f6 link dim br-pill ph3 pv2 mb2 dib white bg-dark-red">取消</button>
            </div>
        </div>
    );
}

export default CouponForm;
