import { useState, useEffect } from "react";
import "../CSS/Contact.css";
import firebase from "../firebase"; // 导入Firebase
import ChatScreen from "./ChatScreen";
import notificationSound from "../Utilities/notification.mp3"


const db = firebase.firestore(); // 获取Firebase Firestore实例

const Contact = (props) => {
  const { chat, ind, currChat, onPress, unreadMessageCount } = props;
  const maxMsgLength = 13;


  const handlePress = async (chatId) => {
    // 这里调用 Firestore API 更新 employeeUnreadNum 为 0
    await db.collection("chatRooms").doc(chatId).update({
      employeeUnreadNum: 0
    });
    onPress(chatId);
  };

  return (
    <div
      className={
        chat.id === currChat ? "selectedContact contact flex" : "contact flex"
      }
      onClick={() => handlePress(chat.id)}
    >
      <div className="flex-column contactInfo">
        <div className="flex contactHeader">
          <div className="contactName b">{chat.user.name}</div>
          {unreadMessageCount > 0 && <div className="unreadMessageCount">{unreadMessageCount}</div>}
        </div>
        <div className="contactLastMsg">
          {chat.lastMessage.slice(0, maxMsgLength)}
          {chat.lastMessage.length > maxMsgLength ? "..." : ""}
        </div>
      </div>
    </div>
  );
};


const ContactList = (props) => {
  const [chats, setChats] = useState({});
  const notificationAudio = new Audio(notificationSound);
  const [unreadMessageCounts, setUnreadMessageCounts] = useState({});


  // 在这里假设你已经从某个地方获取了当前客服员工的ID
  const employeeId = "CtczBrr4wUMJKVrZDoSf6WFJ3s22";

  useEffect(() => {
    const unsubscribe = db
      .collection("chatRooms")
      .where("employeeId", "==", employeeId)
      .orderBy("lastMessageTime", "desc")
      .onSnapshot(async (snapshot) => {
        const chatRoomsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const customerId = doc.data().customerId;
            const customerDoc = await db.collection("customers").doc(customerId).get();
            const { avatar, username, phoneNumber, email, tempUserName } = customerDoc.data();
            let displayName = username || tempUserName || phoneNumber || email;
            displayName = displayName || "未设置用户名";
            // 如果显示名称是电子邮件，并且长度超过10，则进行缩写
            if (displayName === email && displayName.length > 15) {
              displayName = displayName.slice(0, 10) + '...';
            }
            return {
              id: doc.id,
              user: {
                name: displayName,
                img: avatar,
              },
              lastMessage: doc.data().lastMessage,
              lastMessageTime: doc.data().lastMessageTime,
            };
          })
        );
        const sortedChatRoomsData = chatRoomsData.sort((a, b) => b.lastMessageTime - a.lastMessageTime);
        const chatRoomsObj = sortedChatRoomsData.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        setChats(chatRoomsObj);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribes = [];

    Object.keys(chats).forEach((key) => {
      const unsubscribe = db.collection("chatRooms").doc(key).onSnapshot((doc) => {
        setUnreadMessageCounts(prevUnreadMessageCounts => {
          const newUnreadMessageCounts = { ...prevUnreadMessageCounts };
          const previousCount = prevUnreadMessageCounts[key] || 0;
          const currentCount = doc.data().employeeUnreadNum;

          // // 如果当前未读消息数量大于以前的数量，播放音频
          // if (currentCount > previousCount) {
          //   notificationAudio.play();
          // }

          newUnreadMessageCounts[key] = currentCount;
          return newUnreadMessageCounts;
        });
      });
      unsubscribes.push(unsubscribe);
    });

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [chats]);




  return (
    <div className="contactList">
      {Object.keys(chats).map((key) => {
        return (
          <Contact
            key={key}
            ind={key}
            chat={chats[key]}
            currChat={props.currChat}
            onPress={props.onPress}
            unreadMessageCount={unreadMessageCounts[key] || 0}
          />
        );
      })}
    </div>
  );
};

export default ContactList;
