import React, { useState, useEffect, useRef } from 'react';
import firebase from "../firebase";
import 'tachyons';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const storage = firebase.storage();
const firestore = firebase.firestore();

function ManageAds() {
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(); // create a ref for the file input
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      const carouselCollection = await firestore.collection('carouselImages').get();
      setCarouselImages(carouselCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchImages();
  }, []);

  const uploadImage = async () => {
    const file = selectedFile;
    const storageRef = storage.ref();
    
    // Add a timestamp to the filename to make it unique
    const timestamp = Date.now();
    const uniqueFileName = `${file.name}_${timestamp}`;
    
    const fileRef = storageRef.child(uniqueFileName);
  
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const docRef = firestore.collection('carouselImages').doc();
    await docRef.set({
      id: docRef.id,
      source: fileURL,
      path: uniqueFileName,
    });
  
    setCarouselImages([...carouselImages, { id: docRef.id, source: fileURL, path: uniqueFileName }]);
    setSelectedFile(null);
    fileInputRef.current.value = ""; // reset the file input
  };
  

  const deleteImage = async (id, path) => {
    const docRef = firestore.collection('carouselImages').doc(id);
    await docRef.delete();

    const imageRef = storage.ref().child(path);
    await imageRef.delete();

    setCarouselImages(carouselImages.filter(image => image.id !== id));
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="pa3">
        <FaArrowLeft onClick={() => navigate(-1)} size={32} style={{ margin: 10 }} />
      <h1 className="f4 bold center mw6">Ad Management</h1>
      <div className="flex flex-wrap justify-around">
        {carouselImages.map((image) => (
          <div key={image.id} className="tc mw5">
            <img src={image.source} className="w-100 db outline black-10" alt="carousel"/>
            <button onClick={() => deleteImage(image.id, image.path)} className="f6 link dim br2 ph3 pv2 mb2 dib white bg-dark-red">Delete</button>
          </div>
        ))}
      </div>

      <div className="measure center">
        <input 
          type="file" 
          onChange={fileSelectedHandler} 
          className="input-reset ba b--black-20 pa2 mb2 db w-100" 
          ref={fileInputRef} // assign the ref to the file input
        />
        <button onClick={uploadImage} className="f6 link dim br2 ph3 pv2 mb2 dib white bg-dark-green">Upload</button>
      </div>
    </div>
  );
}

export default ManageAds;
