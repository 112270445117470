export const staff = {
    1: {
        name: '王阿姨',
        status: '清洁中',
        order: 7428,
    },
    2: {
        name: '张阿姨',
        status: '清洁中',
        order: 3228,
    },
    3: {
        name: '李阿姨',
        status: '返程中',
        order: 9873,
    },
    4: {
        name: '胡阿姨',
        status: '正在前往',
        order: 2328,
    },
    5: {
        name: '宋阿姨',
        status: '休息',
        order:0,
    },
}