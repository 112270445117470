import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Audio } from 'react-loader-spinner'
import { FaArrowLeft, FaEdit } from 'react-icons/fa';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "../firebase";
import "../CSS/OrderDetails.css";

Modal.setAppElement('#root')


const OrderDetails = () => {
    const [order, setOrder] = useState({});
    const { orderId } = useParams();
    const navigate = useNavigate();
    const roomTypeMap = {
        Kitchen: "厨房",
        Bathroom: "浴室",
        Other: "其他",
        LivingRoom: "客厅",
        Bedroom: "卧室",
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editCustomer, setEditCustomer] = useState({});
    const orderStatusOptions = ["支付成功", "订单确认", "订单完成", "订单取消"];

    const [editService, setEditService] = useState({});
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const janitorAmountOptions = ["一位阿姨", "两位阿姨"];
    const durationOptions = ["2小时", "3小时", "4小时"];

    const parseAddress = (address) => {
        return `${address.address1} ${address.address2}, ${address.city}, ${address.state} ${address.ZIP}`.trim();
    };

    const parseInfo = (address) => {
        return `${address.firstname} ${address.lastname},${address.email}, ${address.phoneNumber}`.trim();
    };

    const sendNotification = async (orderId, title, body) => {
        try {
            // 获取订单中的uid
            const orderDoc = await firebase.firestore().collection("orders").doc(orderId).get();
            const uid = orderDoc.data().uid;

            // 使用uid获取客户的expoPushToken
            const customerDoc = await firebase.firestore().collection("customers").doc(uid).get();
            const expoPushToken = customerDoc.data().expoPushToken;

            // 如果找不到expoPushToken，直接返回
            if (!expoPushToken) {
                console.log("Cannot find expoPushToken for customer " + uid);
                return;
            }

            // 定义推送消息
            const message = {
                to: expoPushToken,
                sound: "default",
                title: title,
                body: body,
                data: { orderId },
            };

            // 发送推送消息
            await fetch("https://exp.host/--/api/v2/push/send", {
                mode: "no-cors",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Accept-Encoding": "gzip, deflate",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(message),
            });
        } catch (error) {
            console.error("Error sending push notification: ", error);
        }
    }


    const handleOrderStatusChange = (event) => {
        const newStatus = event.target.value;
        firebase
            .firestore()
            .collection("orders")
            .doc(orderId)
            .update({ orderStatus: newStatus })
            .then(() => {
                setOrder({ ...order, orderStatus: newStatus });
            });

        firebase
            .firestore()
            .collection("customers")
            .doc(order.uid)
            .collection("orders")
            .doc(orderId)
            .update({ orderStatus: newStatus })
            .then(() => {
                setOrder({ ...order, orderStatus: newStatus });
            });
    };

    useEffect(() => {
        firebase
            .firestore()
            .collection("orders")
            .doc(orderId)
            .get()
            .then((doc) => {
                setOrder(doc.data());
            });
    }, [orderId]);

    console.log(order);

    const handleOpenModal = () => {
        setEditCustomer(order);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCustomerInfoChange = (field) => (e) => {
        setEditCustomer({
            ...editCustomer,
            [field]: e.target.value,
        });
    };

    const handleSave = () => {
        firebase
            .firestore()
            .collection("orders")
            .doc(orderId)
            .update(editCustomer)
            .then(() => {
                setOrder(editCustomer);
            });

        firebase
            .firestore()
            .collection("customers")
            .doc(order.uid)
            .collection("orders")
            .doc(orderId)
            .update(editCustomer)
            .then(() => {
                setOrder(editCustomer);
                setIsModalOpen(false);
                sendNotification(orderId, "生活帮客服", "您的订单信息已修改");
            });
    };

    // function for handling changes to the service info
    const handleServiceInfoChange = (field) => (e) => {
        setEditService({
            ...editService,
            [field]: e.target.value,
        });
    };

    // function for opening the service info modal
    const handleOpenServiceModal = () => {
        setEditService(order.service);
        setIsServiceModalOpen(true);
    };

    // function for closing the service info modal
    const handleCloseServiceModal = () => {
        setIsServiceModalOpen(false);
    };

    // function for saving changes to the service info
    const handleServiceSave = () => {
        const updatedOrder = {
            ...order,
            service: editService,
        };

        firebase
            .firestore()
            .collection("orders")
            .doc(orderId)
            .update(updatedOrder)
            .then(() => {
                firebase
                    .firestore()
                    .collection("customers")
                    .doc(order.uid)
                    .collection("orders")
                    .doc(orderId)
                    .update(updatedOrder)
                    .then(() => {
                        setOrder(updatedOrder);
                        setIsServiceModalOpen(false);
                        sendNotification(orderId, "生活帮客服", "您的订单信息已修改");
                    });
            });
    };


    return (
        <div className="flex-container">
            <FaArrowLeft onClick={() => navigate(-1)} size={32} style={{ margin: 10 }} />
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    contentLabel="Customer Information Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <h2 className="f4 bold center mw6">更新客户信息</h2>
                    <div className="flex flex-column">
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.firstname} onChange={handleCustomerInfoChange('firstname')} placeholder="First Name" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.lastname} onChange={handleCustomerInfoChange('lastname')} placeholder="Last Name" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.email} onChange={handleCustomerInfoChange('email')} placeholder="Email" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.phoneNumber} onChange={handleCustomerInfoChange('phoneNumber')} placeholder="Phone Number" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.address1} onChange={handleCustomerInfoChange('address1')} placeholder="Address 1" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.address2} onChange={handleCustomerInfoChange('address2')} placeholder="Address 2" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.city} onChange={handleCustomerInfoChange('city')} placeholder="City" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.state} onChange={handleCustomerInfoChange('state')} placeholder="State" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="text" value={editCustomer.ZIP} onChange={handleCustomerInfoChange('ZIP')} placeholder="ZIP" />

                        <button className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" onClick={handleSave}>Save</button>
                        <button className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </Modal>
            )}
            {/* Modal for editing service information */}
            {isServiceModalOpen && (
                <Modal
                    isOpen={isServiceModalOpen}
                    onRequestClose={handleCloseServiceModal}
                    contentLabel="Service Information Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <h2 className="f4 bold center mw6">更新服务信息</h2>
                    <div className="flex flex-column">
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="date" value={editService.serviceDate} onChange={handleServiceInfoChange('serviceDate')} placeholder="Service Date" />
                        <input className="ba b--black-20 pa2 mb2 db w-100" type="time" value={editService.serviceTime} onChange={handleServiceInfoChange('serviceTime')} placeholder="Service Time" />
                        <select className="ba b--black-20 pa2 mb2 db w-100" value={editService.janitorAmount} onChange={handleServiceInfoChange('janitorAmount')}>
                            {janitorAmountOptions.map((amount) => (
                                <option value={amount} key={amount}>
                                    {amount}
                                </option>
                            ))}
                        </select>
                        <select className="ba b--black-20 pa2 mb2 db w-100" value={editService.duration} onChange={handleServiceInfoChange('duration')}>
                            {durationOptions.map((duration) => (
                                <option value={duration} key={duration}>
                                    {duration}
                                </option>
                            ))}
                        </select>
                        <textarea className="ba b--black-20 pa2 mb2 db w-100" value={editService.textDesc} onChange={handleServiceInfoChange('textDesc')} placeholder="备注"></textarea>

                        <button className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" onClick={handleServiceSave}>Save</button>
                        <button className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" onClick={handleCloseServiceModal}>Cancel</button>
                    </div>
                </Modal>
            )}
            {!order.orderId ? (
                <Audio
                    height="80"
                    width="80"
                    radius="9"
                    color="blue"
                    ariaLabel="loading"
                    wrapperStyle={{ margin: 'auto' }}
                    wrapperClass="loading-container"
                />
            ) : (
                <div>
                    <div className="fl w-50">
                        <h4 className="data-item">订单详细信息</h4>
                        <h5 className="data-item">业务信息</h5>
                        <div className="flex data-item">订单ID: {order.orderId}</div>
                        <div className="flex data-item">业务类型: {order.businessType}</div>
                        <div className="flex data-item">服务类型: {order.service && order.service.serviceType}</div>
                        <div className="flex data-item">订单状态:
                            <select value={order.orderStatus} onChange={handleOrderStatusChange}>
                                {orderStatusOptions.map((status) => (
                                    <option value={status} key={status}>
                                        {status}
                                    </option>
                                ))}
                            </select></div>
                        <br />

                        <h5 className="data-item">客户信息<FaEdit onClick={handleOpenModal} size={20} style={{ margin: 10, cursor: 'pointer' }} /></h5>
                        <div className="flex data-item">客户姓名: {order.firstname} {order.lastname}</div>
                        <div className="flex data-item">地址: {order.address1} {order.address2}, {order.city}, {order.state}, {order.ZIP}</div>
                        <div className="flex data-item">邮箱: {order.email}</div>
                        <div className="flex data-item">电话号码: {order.phoneNumber}</div>
                        <br />

                        <h5 className="data-item">支付信息</h5>
                        <div className="flex data-item">原始价格: {order.origPrice}</div>
                        <div className="flex data-item">支付金额: {order.paymentAmount}</div>
                        <div className="flex data-item">服务日期和时间: {order.service && order.service.serviceDate} {order.service && order.service.serviceTime}</div>

                    </div>

                    <div className="fl w-50">
                        {order.businessType === '清洁打扫' && (
                            <>
                                <h5 className="data-item">清洁打扫<FaEdit onClick={handleOpenServiceModal} size={20} style={{ margin: 10, cursor: 'pointer' }} /></h5>
                                <div className="flex data-item">服务日期和时间: {order.service && order.service.serviceDate} {order.service && order.service.serviceTime}</div>
                                <div className="flex data-item">服务类型: {order.service && order.service.serviceType}</div>
                                <div className="flex data-item">服务人员数量: {order.service && order.service.janitorAmount}</div>
                                <div className="flex data-item">额外工具: {order.selectedTools}</div>
                                <div className="flex data-item">服务时长: {order.service.duration}</div>
                                <div className="flex data-item">
                                    远距离费: {order.longDistanceFee ? "$" + order.longDistanceFee : "无"}
                                </div>
                                <div className="flex data-item">
                                    使用优惠券: {order.couponCode ? order.couponCode : "无"}
                                </div>

                                <div className="flex data-item">备注: {order.service.textDesc}</div>
                                <br />

                                <h5 className="data-item">客户户型信息</h5>
                                <div className="flex data-item">房屋种类: {order.service.houseType}</div>
                                <div className="flex data-item">
                                    房间情况:
                                    {order.service.roomNumber && Object.entries(order.service.roomNumber).map(([roomType, number]) => (
                                        <div key={roomType} style={{ marginLeft: '6px' }}>
                                            {roomTypeMap[roomType]}: {number}
                                        </div>
                                    ))}
                                </div>
                                <br />
                            </>
                        )}

                        {order.businessType === '搬家服务' && (
                            <>
                                <h5 className="data-item">搬家服务</h5>
                                <div className="flex data-item">服务日期和时间: {order.service && order.service.serviceDate} {order.service && order.service.serviceTime}</div>
                                <div className="flex data-item">服务时长: {order.service.serviceDuriation}</div>
                                <div className="flex data-item">服务人员数量: {order.service.selectedWorkers}</div>
                                <div className="flex data-item">车型选择: {order.service.selectedCar}</div>
                                <div className="flex data-item">需要爬楼梯: {order.service.needsStairs ? '需要' : '不需要'}</div>
                                <div className="flex data-item">需要拆装家具: {order.service.needsFurnitureDisassembly ? '需要' : '不需要'}</div>
                                <div className="flex data-item">
                                    搬出地: {order.service.fromAddress ? parseAddress(order.service.fromAddress) : 'N/A'}
                                </div>
                                <div className="flex data-item">
                                    目的地: {order.service.toAddress ? parseAddress(order.service.toAddress) : 'N/A'}
                                </div>

                                <div className="flex data-item">备注: {order.service.textDesc}</div>
                                <br />
                            </>
                        )}

                        {order.businessType === '快递服务' && (
                            <>
                                <h5 className="data-item">快递服务</h5>
                                <div className="flex data-item">上门取货时间: {order.service && order.service.serviceDate} {order.service && order.service.serviceTime}</div>
                                <div className="flex data-item">寄件人: {order.service.fromAddress ? parseInfo(order.service.fromAddress) : 'N/A'}</div>
                                <div className="flex data-item">寄件人地址: {order.service.fromAddress ? parseAddress(order.service.fromAddress) : 'N/A'}</div>
                                <div className="flex data-item">收件人: {order.service.fromAddress ? parseInfo(order.service.fromAddress) : 'N/A'}</div>
                                <div className="flex data-item">收件人地址: {order.service.toAddress ? parseAddress(order.service.toAddress) : 'N/A'}</div>
                                <div className="flex data-item">备注: {order.service.textDesc}</div>
                                <br />
                            </>
                        )}

                        {order.businessType === '仓库存储' && (
                            <>
                                <h5 className="data-item">仓库存储</h5>
                                <div className="flex data-item">上门取货时间: {order.service && order.service.serviceDate} {order.service && order.service.serviceTime}</div>
                                <div className="flex data-item">取货地址: {order.address1} {order.address2}, {order.city}, {order.state}, {order.ZIP}</div>
                                <div className="flex data-item">仓储规格: {order.service.storageSize}</div>
                                <div className="flex flex-column data-item w-70 overflow-y-auto overflow-x-hidden word-wrap">仓储物品信息: {order.service.textDesc}</div>
                                <br />
                            </>
                        )}
                    </div>

                </div>
            )}
        </div>
    );

};

export default OrderDetails;
