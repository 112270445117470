export const orders = {
  1: {
    orderId: 1234,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1, 2],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  2: {
    orderId: 1564,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  3: {
    orderId: 2342,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1, 2],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  4: {
    orderId: 4124,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  5: {
    orderId: 7234,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "已完成",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  6: {
    orderId: 8,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  7: {
    orderId: 5672,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "服务中",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  8: {
    orderId: 9120,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "已完成",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  9: {
    orderId: 12,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "服务中",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
  10: {
    orderId: 123,
    type: "清洁打扫",
    name: "日常打扫",
    custName: "Runze Zhang",
    serviceMember: [1],
    orderStatus: "待服务",
    createTime: "2023-03-01",
    paymentAmount: 40,
  },
};

//Create a data library with patterns given above
