import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./useAuth";

import Login from "./Components/Login";
import Root from "./Components/Root";
import Performance from "./Components/Performance";
import OrderTracking from "./Components/OrderTracking";
import OrderDetails from "./Components/OrderDetails";
import CustomerList from "./Components/CustomerList";
import EmployeeTracking from "./Components/EmployeeTracking";
import Chat from "./Components/Chat";
import reportWebVitals from "./reportWebVitals";
import ProtectedRoute from "./ProtectedRoute";

import "./CSS/global.css";
import "tachyons";
import OrdersCalendar from "./Components/OrdersCalendar";
import PushNotificationForm from "./Components/PushNotificationForm";
import ManageAds from "./Components/ManageAds";
import Coupons from "./Components/Coupons";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<ProtectedRoute component={Root} />}>
            <Route path="logistics" element={<ProtectedRoute component={Performance} />} />
            <Route path="orders" element={<ProtectedRoute component={OrderTracking} />} />
            <Route path="orders/:orderId" element={<ProtectedRoute component={OrderDetails} />} />
            <Route path="customers" element={<ProtectedRoute component={CustomerList} />} />
            <Route path="employees" element={<ProtectedRoute component={EmployeeTracking} />} />
            <Route path="orderscalendar" element={<ProtectedRoute component={OrdersCalendar} />} />
            <Route path="pushnotificationform" element={<ProtectedRoute component={PushNotificationForm} />} />
            <Route path="manageads" element={<ProtectedRoute component={ManageAds} />} />
            <Route path="coupons" element={<ProtectedRoute component={Coupons} />} />
            <Route path="chats" element={<Chat />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
