import "../CSS/Data.css";
import { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import StaffList from "./StaffList";
import firebase from "../firebase";
import { staff } from "../consts/staff";

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: { today: 0, thisWeek: 0 },
      completion: { today: 0, thisWeek: 0 },
      income: 0,
      date: new Date(Date.now()),
    };
  }

  getDateDifferenceInDays(startDate, endDate) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysDiff;
  }

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection("orders")
      .orderBy("generatedTime", "desc")
      .onSnapshot((snapshot) => {
        let orderDay = 0, orderWeek = 0, completedDay = 0, completedWeek = 0, income = 0;
        const daysInWeek = this.state.date.getDay() === 0 ? 6 : this.state.date.getDay() - 1;

        snapshot.forEach((doc) => {
          const docDate = doc.data().generatedTime.toDate();
          const paymentAmount = Number(doc.data().paymentAmount); // Convert paymentAmount to a number
          if (!isNaN(paymentAmount)) { // Ensure paymentAmount is a number
            if (
              docDate.getDate() === this.state.date.getDate() &&
              docDate.getMonth() === this.state.date.getMonth() &&
              docDate.getFullYear() === this.state.date.getFullYear()
            ) {
              orderDay++;
              orderWeek++;
              income += paymentAmount;
              if (doc.data().status === "已完成") {
                completedDay++;
                completedWeek++;
              }
            } else if (
              this.getDateDifferenceInDays(docDate, this.state.date) <= daysInWeek
            ) {
              orderWeek++;
              if (doc.data().status === "已完成") {
                completedWeek++;
              }
            }
          }
        });

        // Set the income to 2 decimal places
        income = parseFloat(income.toFixed(2));

        this.setState({
          orders: { today: orderDay, thisWeek: orderWeek },
          completion: { today: completedDay, thisWeek: completedWeek },
          income: income,
        });
      });
  }



  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { orders, completion, income } = this.state;

    return (
      <div className="data">
        <div className="title dataTitle">今日数据</div>
        <div className="datacontent">
          <div className="orderStat">
            <div className="contentTitle orderTitle blueText">订单状态</div>
            <div className="progressbar">
              <div className="progressData">
                <div className="greyText progText"> 今日 </div>
                <div className="progValue">
                  {completion["today"]}/{orders["today"]}
                </div>
              </div>
              <ProgressBar now={(completion["today"] / orders["today"]) * 100} bg="info" />

              <div className="progressData">
                <div className="greyText progText progBot"> 本周 </div>
                <div className="progValue">
                  {completion["thisWeek"]}/{orders["thisWeek"]}
                </div>
              </div>
              <ProgressBar now={(completion["thisWeek"] / orders["thisWeek"]) * 100} variant="warning" bg="info" />
            </div>
            <div className="comparison">
              <div className="compLeft">
                <div className="money">
                  {income.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div className="greyText compText">今日收入</div>
              </div>

              <div className="compMid">
                <div className="compValue">
                  {income.day > income.pastMonth / 30 ? (
                    <img src={require("../imgs/upArrow.png")} alt="none" className="arrow" />
                  ) : (
                    <img src={require("../imgs/downArrow.png")} alt="none" className="arrow" />
                  )}
                  <div>
                    {Math.abs((income.day / (income.pastMonth / 30) - 1) * 100).toFixed(2)}%
                  </div>
                </div>
                <div className="greyText compText">相比上月</div>
              </div>

              <div className="compRight">
                <div className="compValue">
                  {income.day > income.pastYear / 365 ? (
                    <img src={require("../imgs/upArrow.png")} alt="none" className="arrow" />
                  ) : (
                    <img src={require("../imgs/downArrow.png")} alt="none" className="arrow" />
                  )}
                  <div>
                    {Math.abs((income.day / (income.pastYear / 365) - 1) * 100).toFixed(2)}%
                  </div>
                </div>
                <div className="greyText compText">去年同比</div>
              </div>
            </div>
          </div>
          <div className="staffStat">
            <StaffList staff={staff} />
          </div>
        </div>
      </div>
    );
  }
}

export default Data;
