import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

Chart.overrides["doughnut"].plugins.legend.display = false;

const DoughnutChart = (props) => {
  const noReserve =
    props.data.cleaing === 0 &&
    props.data.shipping === 0 &&
    props.data.moving === 0 &&
    props.data.storing === 0;

  const noReserveData = {
    labels: ["无预约"],
    datasets: [
      {
        data: [1],
        backgroundColor: ["#555858"],
        hoverOffset: 4,
      },
    ],
  };

  const noReserveOptions = {
    cutout: "60",
    rotation: 90,
    plugins: {
      tooltip: {
        // enabled: false,
      },
    },
  };

  const data = {
    labels: ["清洁打扫", "快递服务", "搬家服务", "仓库存储"],
    datasets: [
      {
        label: "Reservations",
        data: [
          props.data.cleaning,
          props.data.shipping,
          props.data.moving,
          props.data.storing,
        ],
        backgroundColor: ["#F7B321", "#ff6f09", "#59C0E4", "rgb(0, 0, 255)"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    cutout: "60",
    rotation: 90,
  };

  return (
    <div className="doughnutChart">
      {noReserve ? (
        <Doughnut
          labels={noReserveData.labels}
          data={noReserveData}
          options={noReserveOptions}
        />
      ) : (
        <Doughnut labels={data.labels} data={data} options={options} />
      )}
    </div>
  );
};

export default DoughnutChart;
