import React, { useState, useEffect } from 'react';
import firebase from "../firebase";
import 'tachyons';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import CouponForm from './CouponForm';
import moment from 'moment';

const firestore = firebase.firestore();

function Coupons() {
    const [coupons, setCoupons] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCoupons = async () => {
            const couponsCollection = await firestore.collection('coupons').get();
            setCoupons(couponsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };

        fetchCoupons();
    }, []);

    const deleteCoupon = async (id) => {
        const docRef = firestore.collection('coupons').doc(id);
        await docRef.delete();

        setCoupons(coupons.filter(coupon => coupon.id !== id));
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div className="pa3">
            <FaArrowLeft onClick={() => navigate(-1)} size={32} style={{ margin: 10 }} />
            <div className="flex justify-between items-center">
                <h1 className="f4 bold mw6">优惠券管理</h1>
                <button onClick={toggleModal} className="f6 link dim br-pill ph3 pv2 mb2 dib white bg-green">
                    <FaPlus size={20} />
                    <span>添加优惠券</span>
                </button>
            </div>

            <div className="overflow-auto" style={{ maxHeight: '60vh' }}>
                <ul className="list pl0">
                    {coupons.map((coupon) => (
                        <li key={coupon.id} className="flex justify-between items-center lh-copy pa3 ph0-l bb b--black-10">
                            <div className="pl3 flex-auto">
                                <span className="f6 db black-70">优惠代码：{coupon.code}</span>
                                <span className="f6 db black-70">折扣(金额)：{coupon.discount}</span>
                                <span className="f6 db black-70">限制次数：{coupon.limit}</span>
                                <span className="f6 db black-70">使用次数：{coupon.usedCount}</span>
                                <span className="f6 db black-70">
                                    创建时间：{coupon.createdAt ? moment(new firebase.firestore.Timestamp(coupon.createdAt.seconds, coupon.createdAt.nanoseconds).toDate()).format('YYYY-MM-DD HH:mm') : '正在获取...'}
                                </span>
                            </div>
                            <div className="pl3 inline-flex items-center">
                                <button onClick={() => deleteCoupon(coupon.id)} className="f6 link dim ba ph3 pv2 mb2 dib dark-red">删除</button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {showModal && <CouponForm setCoupons={setCoupons} coupons={coupons} closeModal={toggleModal} />}
        </div>
    );
}

export default Coupons;
