import '../CSS/Navigation.css';

export const NavigationEn = ({ state, onPress }) => {
    
    return (
      <div className="Navigation">
        <div className='options'>
          {state === 0 
            ? <button className='selectedButton'>Performance</button>
            : <button className='navButton' onClick={() => onPress(0)}>Performance</button>}
          {state === 1 
            ? <button className='selectedButton'>Tracker</button>
            : <button className='navButton' onClick={() => onPress(1)}>Tracker</button>}
          {state === 2 
            ? <button className='selectedButton'>Planner</button>
            : <button className='navButton' onClick={() => onPress(2)}>Planner</button>}
          {state === 3 
            ? <button className='selectedButton'>Customers</button>
            : <button className='navButton' onClick={() => onPress(3)}>Customers</button>}
          {state === 4 
            ? <button className='selectedButton'>Invoice</button>
            : <button className='navButton' onClick={() => onPress(4)}>Invoice</button>}
        </div>
        <div className="content">
        
        </div>
      </div>
    );
}
