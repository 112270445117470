import "../CSS/Performance.css";
import Data from "./Data";
import Reservation from "./Reservation";
import Income from "./Income";
import OrderData from "./OrderData";

const Performance = () => {
    return(
        <div className='performance'>
          <div className="top">
            <Data/>
            <Reservation/>
          </div>
          <div className="bottom">
            <Income/>
            <OrderData/>
          </div>
        </div>
    );
}

export default Performance;