import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import firebase from "../firebase";
import { debounce } from 'lodash';

const PushNotificationForm = () => {
    const [users, setUsers] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [sentUsersCount, setSentUsersCount] = useState(0);
    const [sendTime, setSendTime] = useState(0);
    const navigate = useNavigate();

    const handleFormSubmit = useCallback(debounce(async (e) => {
        e.preventDefault();

        setIsSending(true);
        setSentUsersCount(0);
        setSendTime(Date.now());

        for (const user of users) {
            if (user.expoPushToken) {
                await sendPushNotification(user.expoPushToken, title, message);
                setSentUsersCount((prevCount) => prevCount + 1);
            }
        }

        setIsSending(false);
        setTitle('');
        setMessage('');
    }, 1000), [users, title, message]);

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection("customers")
            .onSnapshot((snapshot) => {
                const newUsers = [];
                snapshot.forEach((doc) => {
                    newUsers.push({ uid: doc.id, ...doc.data() });
                });
                setUsers(newUsers);
            });

        return () => unsubscribe();
    }, []);

    const sendPushNotification = async (token, title, message) => {
        const response = await fetch('https://exp.host/--/api/v2/push/send', {
            mode: "no-cors",
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                to: token,
                sound: 'default',
                title: title,
                body: message,
            }),
        });
    };

    return (
        <div className="pa4 black-80">
            <div className="fl w-100">
                <FaArrowLeft onClick={() => navigate(-1)} size={32} style={{ margin: 10 }} className="pointer dim" />
            </div>
            <h2 className="fl w-100 tc">发送全局推送通知</h2>
            <div className="measure">
                <label htmlFor="title" className="f6 b db mb2">通知标题:</label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="输入通知标题"
                    className="input-reset ba b--black-20 pa2 mb2 db w-100"
                />
            </div>
            <div className="measure">
                <label htmlFor="message" className="f6 b db mb2">通知内容:</label>
                <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="输入通知内容"
                    className="input-reset ba b--black-20 pa2 mb2 db w-100"
                    style={{ height: '150px' }}
                />
            </div>
            <button
                type="submit"
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
                disabled={isSending}
                onClick={handleFormSubmit}
            >
                发送通知
            </button>
            {isSending && (
                <div className="mt3">
                    <p>正在发送通知...</p>
                </div>
            )}
            {!isSending && sentUsersCount > 0 && (
                <div className="mt3">
                    <p>
                        成功发送通知给 {sentUsersCount} 个用户！
                        发送耗时: {((Date.now() - sendTime) / 1000).toFixed(2)} 秒
                    </p>
                </div>
            )}
        </div>
    );
};

export default PushNotificationForm;
