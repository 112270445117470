import { useState, useEffect, useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "../CSS/NotificationBox.css";
import firebase from "../firebase"; // 导入Firebase
import notificationSound from "../Utilities/notification.mp3"

const db = firebase.firestore(); // 获取Firebase Firestore实例

const Notification = (props) => {
  const notif = props.notification;
  return (
    <div className="Notification">
      {notif.type === "user" ? (
        <div className="notif">
          <img
            className="notiImg"
            src={require("../imgs/ayi.png")}
            alt="none"
          />
          <div className="notiContent">
            <div className="notiName"> {notif.user} </div>
            <div className="fr">
              <div className="notiContext">
                {" "}
                {notif.name} ：{notif.status}{" "}
              </div>
              <div className="notiContext lightBlueText">
                订单#{notif.orderNum}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="notif">
          <img
            className="notiImg"
            src={require("../imgs/Purchase Order.png")}
            alt="none"
          />
          <div className="notiContent">
            <div className="notiName"> {notif.user} </div>
            <div className="notiContext">
              {" "}
              {notif.name} ：{notif.order.service} {notif.order.date}{" "}
              {notif.order.staffNum}{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const NotificationBox = (props) => {
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const employeeId = "CtczBrr4wUMJKVrZDoSf6WFJ3s22";
  const notificationAudio = new Audio(notificationSound);
  const prevTotalUnreadMessagesRef = useRef();

  useEffect(() => {
    prevTotalUnreadMessagesRef.current = totalUnreadMessages;
  }, [totalUnreadMessages]);

  const prevTotalUnreadMessages = prevTotalUnreadMessagesRef.current;

  useEffect(() => {
    const unsubscribe = db
      .collection("chatRooms")
      .where("employeeId", "==", employeeId)
      .onSnapshot((snapshot) => {
        let totalUnread = 0;
        snapshot.docs.forEach((doc) => {
          const employeeUnreadNum = doc.data().employeeUnreadNum;
          totalUnread +=
            typeof employeeUnreadNum === "number" ? employeeUnreadNum : 0;
        });
        console.log(totalUnread);
        setTotalUnreadMessages(totalUnread);

        // 如果总未读消息数量增加，播放通知声音
        if (totalUnread > prevTotalUnreadMessages) {
          notificationAudio.play();
        }
      });

    return () => {
      unsubscribe();
    };
  }, [prevTotalUnreadMessages]); // 注意这里添加了 prevTotalUnreadMessages


  console.log(totalUnreadMessages);

  return (
    <div className="NotificationBox">
      <LinkContainer to="/home/chats">
        <div className="notiTitle">
          客服与通知 {">>"}
          {totalUnreadMessages > 0 && (
            <span className="badge">{totalUnreadMessages}</span>
          )}
        </div>
      </LinkContainer>
      <div className="notiList">
        {Object.keys(props.notifications).map((key) => (
          <Notification notification={props.notifications[key]} key={key} />
        ))}
      </div>
    </div>
  );
};

export default NotificationBox;
