import React from "react";
import { Outlet } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "../CSS/Navigation.css";

export const NavigationCh = (props) => {
  return (
    <div className="Navigation">
      <div className="options">
        <LinkContainer to="/home/logistics">
          <button className="navButton">运营数据</button>
        </LinkContainer>
        <LinkContainer to="/home/orders">
          <button className="navButton">订单跟踪</button>
        </LinkContainer>
        <LinkContainer to="/home/customers">
          <button className="navButton">客户管理</button>
        </LinkContainer>
        <LinkContainer to="/home/employees">
          <button className="navButton">人员管理</button>
        </LinkContainer>
        <LinkContainer to="/home/orderscalendar">
          <button className="navButton">派单系统</button>
        </LinkContainer>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};
