import "../CSS/StaffList.css"

const Staff = (props) => {
    const {name, status, order} = props.staff;
    return(
        <div className="staff">
          <img src={require("../imgs/ayi.png")} alt="none" className="ayi"/>
          <div>
            <div className="ayiName">{name}</div>
            <div className="ayiInfo">
              <div className="ayiInfoText">状态 :</div>
              <div className="ayiInfoText blueText">{status}</div>
              {order !== 0 
                ? <div className="ayiOrder">
                    <div className="ayiInfoText">订单 :</div>
                    <div className="ayiInfoText lightBlueText">#{order} </div>
                  </div>
                : <div></div>
              }  
            </div>
          </div>
        </div>
    )
}

const StaffList = (props) => {
    return(
        <div className="staffList">
          <div className='contentTitle staffTitle'>人员状态</div>
          {
              Object.keys(props.staff).map((key) => {
                  return <Staff staff={props.staff[key]} key={key}/>
              })
          }
        </div>
    )
}

export default StaffList;