import { React, Component, useState, useEffect } from "react";
import { NavigationEn } from "./NavigationEn";
import { NavigationCh } from "./NavigationCh";
import Info from "./Info";
import firebase from "../firebase";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../CSS/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const auth = firebase.auth().currentUser;
// const navigate = useNavigate();

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "ch",
      selectedDate: new Date(Date.now()),
      user: null,
    };
  }

  setSelectedDate = (newDate) => {
    this.setState({ selectedDate: newDate });
  };

  componentDidMount() {
    console.log(auth);
  }

  render() {
    const { user, language, selectedDate } = this.state;

    return (
      <div>
        <div className="App">
          <Info
            user={user}
            selectedDate={selectedDate}
            setSelectedDate={this.setSelectedDate}
          />
          {language === "ch" ? (
            <NavigationCh user={this.state.user} />
          ) : (
            <NavigationEn user={this.state.user} />
          )}
        </div>
      </div>
    );
  }
}

export default Root;
